<template>
  <div class="vuse-content-wrapper">
    <v-container fluid>
      <smart-breadcrumbs :items="navigationElements" />
      <horizontal-menu :menuItems="menuItems" />

      <v-container fluid>
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header disable-icon-rotate>
              Inicio
              <template v-slot:actions>
                <v-icon color="teal"> mdi-check </v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row align="center">
                <v-col class="d-flex" cols="12" sm="6">
                  <v-select
                    :items="items"
                    label="Seleccione un formato"
                    @change="swapComponent($event)"
                  />
                </v-col>
                <v-col class="d-flex" cols="12" sm="6">
                  <v-select :items="items_ubication" label="Seleccione una ubicacion" />
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header disable-icon-rotate>
              Panel de estadisticas
              <template v-slot:actions>
                <v-icon color="error"> mdi-alert-circle </v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row align="center">
                <v-col class="d-flex" cols="12" sm="6">
                  <v-select :items="items" label="Seleccione un formato" />
                </v-col>
                <v-col class="d-flex" cols="12" sm="6">
                  <v-select :items="items_ubication" label="Seleccione una ubicacion" />
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-container>
      <div :is="currentComponent" />
    </v-container>
  </div>
</template>
<script>
  import HorizontalMenu from '@/components/App/HorizontalMenu'
  import Layout1 from '@/components/Layouts/Layout1'
  import Layout2 from '@/components/Layouts/Layout2'
  import Layout3 from '@/components/Layouts/Layout3'
  export default {
    components: { HorizontalMenu, Layout1, Layout2, Layout3 },
    data: () => ({
      items: ['Layout1', 'Layout2', 'Layout3'],
      items_ubication: ['Inicio', 'Estadisticas'],
      currentComponent: null,
      menuItems: [
        {
          id: 1,
          name: 'Gestor de Métricas',
          icon: 'mdi-account-network',
          link: '/administrative/human-talent/metrics',
        },
      ],
      navigationElements: [
        {
          text: 'Talento Humano',
          disabled: false,
          href: '/administrative/human-talent/admin-panel',
        },
        {
          text: 'Desempeño de la Empresa',
          disabled: true,
          href: '',
        },
      ],
    }),
    methods: {
      swapComponent: function (component) {
        this.currentComponent = component
      },
    },
  }
</script>
<style lang="scss">
  .v-breadcrumbs__item {
    font-size: 12px;
  }
  .v-breadcrumbs li:nth-child(even) {
    padding: 0 5px;
  }
  .v-breadcrumbs {
    padding: 5px 10px !important;
  }
</style>
